<template>
  <div class="min-h-screen">
    <section class="layout bg-blue-darker pt-32 pb-44">
      <div class="container pt-20 pb-10">
        <h1 class="text-4xl font-medium leading-normal text-white text-center">Lorem ipsum dolor</h1>
      </div>
      <circle-bg />
      <circle-bg />
      <circle-bg />
      <circle-bg />
      <circle-bg />
      <circle-bg />
      <skewed-separator class="text-white" />
    </section>
    <div class="container overlap">
      <div class="flex justify-center">
        <card class="w-full md:w-1/2 p-4">
          <notice v-if="errors != false" type="error" :messages="errors" />
          <form v-if="show">
            <input-container icon="letter">
              <input type="email" v-model="form.email" placeholder="Email" required />
            </input-container>
            <input-container icon="key">
              <input type="password" v-model="form.password" placeholder="Password" required />
            </input-container>
            <btn class="btn-orange w-full" @click.native="submit">Reset Password</btn>
          </form>
          <div class="flex justify-between mt-4">
            <router-link class="text-sm text-grey-darker no-underline" :to="{name: 'register'}">Don't have an account?</router-link>
            <router-link class="text-sm text-grey-darker no-underline" :to="{name: 'login'}">Sign in</router-link>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import CircleBg from '../partials/CircleBg';
import SkewedSeparator from '../partials/SkewedSeparator';
import Icon from '../partials/Icon';
import InputContainer from '../partials/InputContainer';
import Btn from '../partials/Btn';
import Notice from '../partials/Notice';
export default {
  components: { Notice, Btn, InputContainer, Icon, SkewedSeparator, CircleBg },
  data() {
    return {
      form: {
        email: '',
        password: '',
        token: '',
      },
      show: true,
      errors: false,
    };
  },
  mounted() {
    this.form.token = this.$route.query.token;
  },
  methods: {
    submit() {
      this.errors = false;

      this.$store.dispatch(window.store.POST_USER_RESET_PASSWORD, this.form)
        .then(() => {
          this.$router.push({ name: 'login' });
        })
        .catch((errors) => {
          this.errors = errors;
        });
    },
  },
};
</script>
